<template>
  <div class="page-content">
    <div class="container">
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
          tabindex="0"
        >
          <div class="row mb-4">
            <div class="col-lg-8">
              <a
                v-on:click="this.$router.push('/home')"
                class="float-start mt-1 me-4 cursor-pointer"
              >
                <svg
                  width="12"
                  height="16"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                    stroke="#001D21"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
              <h1 class="page-title ms-4">Quotation</h1>
            </div>
            <div class="col-lg-4">
              <div class="input-group page-search-control">
                <span
                  @click="getQuotationList(currentPage)"
                  class="input-group-text cursor-pointer"
                  id="inputGroup-sizing-default"
                  ><img
                    src="static/images/search-icon-dark.svg"
                    width="14"
                    class="icon"
                /></span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search by vehicle or workshop"
                  v-model="keyword"
                  @keydown.enter="getQuotationList(currentPage)"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="col-lg-6 mb-4"
              v-for="(item, index) in quotationList"
              :key="index"
            >
              <div class="card order-card cursor-pointer" style="height: 100%;">
                <div class="card-header">
                  <div class="row align-items-center">
                    <div class="col-lg-6">
                      <div class="font-14 mb-1">
                        {{
                          $helperService.dateFormateForOrder(item.created_at)
                        }}
                      </div>
                      <div class="font-14 text-secondary">
                        Quotation ID:
                        {{
                          item && item.quotation_no ? item.quotation_no : "-"
                        }}
                      </div>
                    </div>
                    <div class="col-lg-6 text-end">
                      <span
                        class="badge bg-warning font-14 font-medium"
                        v-if="item?.status === 'PENDING'"
                      >
                        {{ $helperService.getTitleCase("IN-PROGRESS") }}
                      </span>
                      <span
                        class="badge bg-info font-14 font-medium"
                        v-else-if="item?.status === 'QUOTE'"
                      >
                        {{ $helperService.getTitleCase("Send-quote") }}</span
                      >

                      <span class="badge bg-info font-14 font-medium" v-else>
                        {{ $helperService.getTitleCase(item.status) }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="card-body" v-on:click="moveToOrderDetails(item.id)">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="border p-2 rounded text-center mb-2">
                        <img
                          class="img-fluid mb-2"
                          style="width: 90px; height: 70px"
                          :src="
                            item?.uservehicle.manufacturer_model?.icon ||
                            '/static/images/default.png'
                          "
                          alt=""
                        />
                        <p class="mb-0">
                          {{
                            item?.uservehicle.manufacturer_model?.title || ""
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div
                        v-if="item?.uservehicle?.vehicle_registration_number"
                        class="p-2 bg-secondary-light d-inline-block rounded px-3 mb-2"
                      >
                        {{ item?.uservehicle?.vehicle_registration_number }}
                      </div>
                      <div class="mb-2">
                        <span class="text-secondary">Fuel type:</span>
                        <span class="ms-2">{{
                          item?.uservehicle?.fuel?.title || "-"
                        }}</span>
                      </div>
                      <div class="mb-2">
                        <span class="text-secondary">Transmission type:</span>
                        <span class="ms-2">{{
                          item?.uservehicle?.transmission?.title || "-"
                        }}</span>
                      </div>
                    </div>
                    <div class="col-12 d-flex gap-2 mb-2">
                      <div class="mb-1">
                        <span class="text-secondary">Services:</span>
                      </div>
                      <div>
                        <p class="text mb-0">
                          {{ item.category.title }}
                        </p>
                      </div>
                    </div>
                    <div class="col-12 d-flex gap-2 mb-2">
                      <div class="mb-1">
                        <span class="text-secondary">Requested Brand: </span>
                      </div>
                      <div>
                        <p class="text mb-0">
                          {{
                            item.brands
                              .map((obj) => obj?.brand?.title)
                              .join(", ")
                          }}
                        </p>
                      </div>
                    </div>

                    <div class="col-12 d-flex gap-2 mb-3">
                      <span class="text-secondary">
                        Quotation &nbsp;{{
                          item.status == "PENDING" ? "Recieved" : "Sent"
                        }}:
                      </span>
                      <div>
                        <p class="text mb-0">
                          {{
                            $helperService.dateFormateForOrderWithoutDay(
                              item.status == "PENDING"
                                ? item.created_at
                                : item.updated_at
                            )
                          }}
                        </p>
                      </div>
                    </div>
                    <!-- <div class="col-lg-6">
                      <table class="table table-borderless mb-0">
                        <tr>
                          <td class="text-secondary">Pick-up</td>
                          <td
                            v-if="
                              item?.workshop?.pickup_drop?.is_pickup === 'Y'
                            "
                            class="font-medium"
                          >
                            ₹{{ item.workshop.pickup_drop.pickup_cost }}
                          </td>
                          <td class="font-medium" v-else>-</td>
                        </tr>
                        <tr>
                          <td class="text-secondary">Drop</td>
                          <td
                            v-if="item?.workshop?.pickup_drop?.is_drop === 'Y'"
                            class="font-medium"
                          >
                            ₹{{ item.workshop.pickup_drop.drop_cost }}
                          </td>
                          <td class="font-medium" v-else>-</td>
                        </tr>
                      </table>
                    </div> -->
                  </div>
                </div>

                <div class="card-footer d-flex gap-3">
                  <button
                    v-if="item?.status === 'PENDING'"
                    class="btn btn-outline-danger bg-danger-subtle rounded-3 w-100"
                    style="padding: 10px 20px"
                    data-bs-toggle="modal"
                    data-bs-target="#CancelQuoteModal"
                    v-on:click="storeUUID(item?.id)"
                  >
                    Cancel
                  </button>
                  <button
                    v-if="item?.status === 'PENDING'"
                    class="btn btn-primary w-100"
                    v-on:click="handleSendQuote(item?.id)"
                  >
                    Send Quote
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; justify-content: end">
            <Pagination
              @page-change="pageChange"
              @items-per-page-change="itemsPerPageChange"
              ref="listPagination"
            />
          </div>
        </div>
        <ErrorComponent
          @retry="getQuotationList(currentPage)"
          ref="errorComponent2"
        />
      </div>
      <div
        class="tab-pane fade"
        id="pills-profile"
        role="tabpanel"
        aria-labelledby="pills-profile-tab"
        tabindex="0"
      ></div>
    </div>
  </div>

  <!-- -------------------------------Cancel Model---------------------------------------- -->
  <div
    class="modal fade"
    id="CancelQuoteModal"
    tabindex="-1"
    aria-labelledby="CancelQuoteModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-sm">
      <div class="modal-content">
        <div class="modal-body text-center p-4">
          <p class="font-medium font-16 mb-4">
            Are you sure you want to Cancel?
          </p>
          <div class="d-flex gap-3">
            <button
              id="close-accept-popup"
              type="button"
              class="btn btn-outline-danger w-100"
              data-bs-dismiss="modal"
            >
              No
            </button>
            <button
              id="cancel-order"
              type="button"
              class="btn btn-primary w-100"
              v-on:click="CancelQuote"
            >
              YES
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent.vue";
import Pagination from "@/components/Pagination.vue";
export default {
  name: "QuotationPage",
  components: {
    ErrorComponent,
    Pagination,
  },

  data() {
    return {
      quotationList: [],
      workshop_id: localStorage.getItem("workshop_id"),
      page_type: "",
      uuid: "",

      id: null,
      offset: -1,
      keyword: "",
      currentPage: this.$store.getters.getCurrentPage
        ? this.$store.getters.getCurrentPage
        : 0,
    };
  },

  mounted() {
    localStorage.setItem("page_type", "HOME");
    this.$store.commit("setPageType", "HOME");
    this.page_type = "HOME";
    if (this.workshop_id) {
      this.getQuotationList(this.currentPage);
    }
  },
  methods: {
    getQuotationList(page) {
      if (this.$refs.errorComponent2) {
        this.$refs.errorComponent2.showLoader(true);
      }
      this.quotationList = [];
      this.$api
        .getAPI({
          _action: "/quotation/list/" + this.workshop_id,
          _body: { offset: page, keyword: this.keyword },
          _hide_loader: true,
        })
        .then((res) => {
          if (res) {
            this.offset = res.next_offset;
          }
          if (res && res.list && res.list.length > 0) {
            this.quotationList = res.list;
          } else {
            if (this.$refs.errorComponent2) {
              this.$refs.errorComponent2.setTitle("No Data Available");
            }
          }
          if (page >= 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
                this.$refs.listPagination.setCurrentPage(page + 1);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent2) {
            this.$refs.errorComponent2.showLoader(false);
          }
        })
        .catch((error) => {
          if (this.$refs.errorComponent2) {
            this.$refs.errorComponent2.showLoader(false);
            this.$refs.errorComponent2.setTitle("");
          }
          console.error(error);
        });
    },

    CancelQuote() {
      if (this.id) {
        this.$api
          .postAPI({
            _action: `cancel-quote/${this.id}`,
            _buttonId: "cancel-order",
          })
          .then((res) => {
            this.getQuotationList(this.currentPage);
            this.$toast.open({
              message: res.message,
              type: "success",
              duration: 3000,
              position: "top-right",
            });

            let modalElement = document.getElementById("close-accept-popup");
            modalElement.click();
          })
          .catch((error) => {
            this.uuid = "";
            console.error(error);
          });
      }
    },
    storeUUID(uuid) {
      this.id = uuid;
    },
    handleSendQuote(id) {
      this.$router.push(`/quotation-detail/${id}`);
    },

    moveToOrderDetails(id) {
      this.$router.push(`/quotation-detail/${id}`);
    },
    pageChange(offset) {
      this.$store.commit("setCurrentPage", offset - 1);
      this.getQuotationList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getQuotationList(this.currentPage);
    },
  },
};
</script>

