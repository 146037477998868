<template>
  <div class="invoice-page">
    <div class="container py-5">
      <div class="row mb-4 justify-content-center">
        <a v-on:click="$router.go(-1)" class="cursor-pointer">
          <svg
            width="12"
            height="16"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
              stroke="#001D21"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </a>
        <div class="col-lg-7 text-center">
          <h1 class="page-title">Quotation Detail</h1>
        </div>
      </div>
      <div class="card form-style">
        <div class="card-header text-center">
          <h1 class="font-20 font-bold text-center mb-0">Estimate</h1>
        </div>
        <div class="card-body pt-2">
          <div class="row mb-4 border-bottom pb-2 align-items-center">
            <div class="col-lg-6">
              <table class="table table-borderless mb-0">
                <tr>
                  <td class="text-secondary" width="90">Order ID</td>
                  <td width="90">{{ this.orderData?.order?.order_no }}</td>
                  <td>
                    {{
                      $helperService.sendFormattedDateDMY(
                        this.orderData?.order?.created_at
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="text-secondary">Estimate No</td>
                  <td>
                    {{ this.orderData?.order?.order_no?.replace("ORD", "EST") }}
                  </td>
                  <td>{{ $helperService.sendFormattedDateDMY(new Date()) }}</td>
                </tr>
              </table>
            </div>
            <div class="col-lg-6 text-end">
              <div class="font-18 font-medium text-lg-end text-center mt-lg-0 mt-3">
                {{ this.orderData?.order?.workshop?.workshop_name }}
              </div>
            </div>
          </div>

          <Form @submit="handleOnSave">
            <div class="row mb-4">
              <div class="col-lg-6">
                <div class="card p-3 mb-lg-0 mb-3">
                  <div class="row">
                    <div class="col-lg-6 mb-3">
                      <label class="form-label"
                        >Vehicle Number<span class="text-danger">*</span></label
                      >
                      <Field
                        type="text"
                        id="vehicle_number"
                        class="form-control"
                        v-model="orderData.vehicle_number"
                        name="vehicle_number"
                        placeholder="Enter Vehicle Number"
                        rules="required:Vehicle Number"
                        :validateOnInput="true"
                        @blur="checkVehicleRegistrationNumber"
                      />
                      <ErrorMessage
                        name="vehicle_number"
                        component="span"
                        class="text-danger"
                      />
                    </div>
                    <div class="col-lg-6 mb-3">
                      <label class="form-label">Make & Model (optional)</label>
                      <Field
                        type="text"
                        id="model"
                        class="form-control"
                        v-model="orderData.model"
                        name="model"
                        placeholder="Enter Model"
                        :validateOnInput="true"
                      />
                      <ErrorMessage name="model" component="span" class="text-danger" />
                    </div>
                    <div class="col-lg-6 mb-3">
                      <label class="form-label"
                        >Customer Name<span class="text-danger">*</span></label
                      >
                      <Field
                        type="text"
                        id="customer_name"
                        class="form-control"
                        v-model="orderData.customer_name"
                        name="customer_name"
                        placeholder="Enter Customer Name "
                        rules="required:Customer Name "
                        :validateOnInput="true"
                      />
                      <ErrorMessage
                        name="customer_name"
                        component="span"
                        class="text-danger"
                      />
                    </div>

                    <div class="col-lg-6 mb-3">
                      <label class="form-label"
                        >Job Type<span class="text-danger">*</span></label
                      >

                      <Field
                        v-slot="{ field }"
                        name="job_type"
                        rules="required:Job Type,true"
                        :validateOnInput="true"
                        v-model="orderData.job_type"
                      >
                        <select
                          class="form-select"
                          v-model="orderData.job_type"
                          name="job_type"
                          v-bind="field"
                        >
                          <option :value="null">Select Job Type</option>
                          <option value="FREE">Free</option>
                          <option value="PAID">Paid</option>
                        </select>
                      </Field>

                      <ErrorMessage
                        name="job_type"
                        component="span"
                        class="text-danger"
                      />
                    </div>

                    <div class="col-lg-12 mb-3">
                      <label class="form-label"
                        >Address<span class="text-danger">*</span></label
                      >
                      <Field
                        type="text"
                        id="address"
                        class="form-control"
                        v-model="orderData.address"
                        name="address"
                        placeholder="Enter Address "
                        rules="required:Address "
                        :validateOnInput="true"
                        aria-disabled=""
                      />
                      <ErrorMessage name="address" component="span" class="text-danger" />
                    </div>
                    <div class="col-lg-6 mb-3">
                      <label class="form-label">Chassis Number (optional)</label>
                      <Field
                        type="text"
                        id="chassis_number"
                        class="form-control"
                        v-model="orderData.chassis_number"
                        name="chassis_number"
                        placeholder="Enter Chassis Number "
                        :validateOnInput="true"
                      />
                      <ErrorMessage
                        name="chassis_number"
                        component="span"
                        class="text-danger"
                      />
                    </div>
                    <div class="col-lg-6 mb-3">
                      <label class="form-label"
                        >Mobile Number<span class="text-danger">*</span></label
                      >
                      <Field
                        type="text"
                        id="mobile_number"
                        class="form-control"
                        v-model="orderData.mobile_number"
                        name="mobile_number"
                        placeholder="Enter Mobile Number "
                        rules="required:Mobile Number "
                        :validateOnInput="true"
                        disabled
                      />
                      <ErrorMessage
                        name="mobile_number"
                        component="span"
                        class="text-danger"
                      />
                    </div>
                    <div class="col-lg-6 mb-3">
                      <label class="form-label">Engine Number (optional)</label>
                      <Field
                        type="text"
                        id="engine_number"
                        class="form-control"
                        v-model="orderData.engine_number"
                        name="engine_number"
                        placeholder="Enter Engine Number "
                        :validateOnInput="true"
                      />
                      <ErrorMessage
                        name="engine_number"
                        component="span"
                        class="text-danger"
                      />
                    </div>
                    <div class="col-lg-6 mb-3">
                      <label class="form-label">GST Number (optional)</label>
                      <Field
                        type="text"
                        id="gst_number"
                        class="form-control"
                        v-model="orderData.gst_number"
                        name="gst_number"
                        placeholder="Enter GST Number "
                        :validateOnInput="true"
                      />
                      <ErrorMessage
                        name="gst_number"
                        component="span"
                        class="text-danger"
                      />
                    </div>
                    <div class="col-lg-6 mb-3">
                      <label class="form-label">Odometer Reading (KM) (optional)</label>
                      <Field
                        type="text"
                        id="odometer_reading"
                        class="form-control"
                        v-model="orderData.odometer_reading"
                        name="odometer_reading"
                        placeholder="Enter Odometer Reading "
                        :validateOnInput="true"
                      />
                      <ErrorMessage
                        name="odometer_reading"
                        component="span"
                        class="text-danger"
                      />
                    </div>
                    <div class="col-lg-6 mb-3">
                      <label class="form-label"
                        >Vehicle Registration Date (optional)</label
                      >
                      <Field
                        name="vehicle_registration_date"
                        id="vehicle_registration_date"
                        v-slot="{ field }"
                        v-model="orderData.vehicle_registration_date"
                      >
                        <Datepicker
                          autoApply
                          v-model="orderData.vehicle_registration_date"
                          inputFormat="dd-MM-yyyy"
                          format="yyyy-MM-dd"
                          placeholder="Select Year"
                          v-bind="field"
                        />
                      </Field>

                      <ErrorMessage name="from_time" class="validation-msg mt-1" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="card p-3">
                  <div class="row">
                    <div class="col-lg-6 mb-3">
                      <label class="form-label"
                        >Workshop Name<span class="text-danger">*</span></label
                      >

                      <Field
                        type="text"
                        id="workshop_name"
                        class="form-control"
                        v-model="orderData.workshop_name"
                        name="workshop_name"
                        placeholder="Enter Workshop Name "
                        rules="required:Workshop Name "
                        :validateOnInput="true"
                        disabled
                      />
                      <ErrorMessage
                        name="customer_name"
                        component="span"
                        class="text-danger"
                      />
                    </div>
                    <div class="col-lg-6 mb-3">
                      <label class="form-label"
                        >GSTIN<span class="text-danger">*</span></label
                      >

                      <Field
                        type="text"
                        id="gstin"
                        class="form-control"
                        v-model="orderData.gstin"
                        name="gstin"
                        placeholder="Enter GSTIN "
                        rules="required:GSTIN "
                        :validateOnInput="true"
                        disabled
                      />
                      <ErrorMessage name="gstin" component="span" class="text-danger" />
                    </div>

                    <div class="col-lg-12 mb-3">
                      <label class="form-label"
                        >Workshop Address<span class="text-danger">*</span></label
                      >
                      <Field
                        type="text"
                        id="workshop_address"
                        class="form-control"
                        v-model="orderData.workshop_address"
                        name="workshop_address"
                        placeholder="Enter Address "
                        rules="required:Address "
                        :validateOnInput="true"
                        disabled
                      />
                      <ErrorMessage
                        name="workshop_address"
                        component="span"
                        class="text-danger"
                      />
                    </div>

                    <div class="col-lg-4 mb-3">
                      <label class="form-label"
                        >Mechanic Name<span class="text-danger">*</span></label
                      >
                      <Field
                        type="text"
                        id="mechanic_name"
                        class="form-control"
                        v-model="orderData.mechanic_name"
                        name="mechanic_name"
                        placeholder="Enter Mechanic Name  "
                        rules="required:Mechanic Name "
                        :validateOnInput="true"
                      />
                      <ErrorMessage
                        name="mechanic_name"
                        component="span"
                        class="text-danger"
                      />
                    </div>
                    <div class="col-lg-4 mb-3">
                      <label class="form-label"
                        >State<span class="text-danger">*</span></label
                      >
                      <Field
                        type="text"
                        id="state"
                        class="form-control"
                        v-model="orderData.state"
                        name="state"
                        placeholder="Enter State "
                        rules="required:State"
                        :validateOnInput="true"
                        disabled
                      />
                      <ErrorMessage name="state" component="span" class="text-danger" />
                    </div>
                    <div class="col-lg-4 mb-3">
                      <label class="form-label"
                        >State Code<span class="text-danger">*</span></label
                      >
                      <Field
                        type="text"
                        id="state_code"
                        class="form-control"
                        v-model="orderData.state_code"
                        name="state_code"
                        placeholder="Enter State Code"
                        rules="required:State Code "
                        :validateOnInput="true"
                        disabled
                      />
                      <ErrorMessage
                        name="state_code"
                        component="span"
                        class="text-danger"
                      />
                    </div>

                    <div class="col-lg-6 mb-3">
                      <label class="form-label"
                        >Insurance opted<span class="text-danger">*</span></label
                      >
                      <div class="d-flex gap-4 mt-2">
                        <div class="form-check">
                          <input
                            v-model="orderData.insurance_opted"
                            class="form-check-input"
                            type="radio"
                            name="insurance_opted"
                            id="yes"
                            value="Y"
                          />
                          <label class="form-check-label" for="yes">Yes</label>
                        </div>
                        <div class="form-check">
                          <input
                            v-model="orderData.insurance_opted"
                            class="form-check-input"
                            type="radio"
                            name="insurance_opted"
                            id="no"
                            value="N"
                          />
                          <label class="form-check-label" for="no">No</label>
                        </div>
                      </div>
                    </div>
                    <template v-if="orderData.insurance_opted === 'Y'">
                      <div class="col-lg-6 mb-3">
                        <label class="form-label"
                          >Insurance Company Name<span class="text-danger">*</span></label
                        >
                        <Field
                          v-slot="{ field }"
                          name="insurance_id"
                          rules="required:insurance,true"
                          :validateOnInput="true"
                          v-model="orderData.insurance_id"
                        >
                          <select
                            id="insurance_id"
                            class="form-select"
                            v-model="orderData.insurance_id"
                            name="insurance_id"
                            :validateOnInput="true"
                            v-bind="field"
                            @change="checkInsurance()"
                          >
                            <option :value="null">Select Insurance</option>
                            <option
                              v-for="item in insuranceListOption"
                              :key="item.id"
                              :value="item.id"
                              :label="item.title"
                            >
                              {{ item.title }}
                            </option>
                          </select>
                        </Field>
                        <ErrorMessage
                          name="insurance_id"
                          component="span"
                          class="text-danger"
                        />
                      </div>
                      <div class="col-lg-12 mb-3">
                        <label class="form-label"
                          >Address<span class="text-danger">*</span></label
                        >
                        <Field
                          type="text"
                          id="insurance_address"
                          class="form-control"
                          v-model="orderData.insurance_address"
                          name="insurance_address"
                          placeholder="Enter Address "
                          :validateOnInput="true"
                          disabled
                        />
                        <ErrorMessage
                          name="insurance_address"
                          component="span"
                          class="text-danger"
                        />
                      </div>
                      <div class="col-lg-6 mb-3">
                        <label class="form-label"
                          >State<span class="text-danger">*</span></label
                        >
                        <Field
                          type="text"
                          id="insurance_state"
                          class="form-control"
                          v-model="orderData.insurance_state"
                          name="insurance_state"
                          placeholder="Enter State "
                          :validateOnInput="true"
                          disabled
                        />
                        <ErrorMessage
                          name="insurance_state"
                          component="span"
                          class="text-danger"
                        />
                      </div>
                      <div class="col-lg-6 mb-3">
                        <label class="form-label"
                          >State Code<span class="text-danger">*</span></label
                        >
                        <Field
                          type="text"
                          id="insurance_state_code"
                          class="form-control"
                          v-model="orderData.insurance_state_code"
                          name="insurance_state_code"
                          placeholder="Enter State Code"
                          :validateOnInput="true"
                          disabled
                        />
                        <ErrorMessage
                          name="insurance_state_code"
                          component="span"
                          class="text-danger"
                        />
                      </div>
                      <div class="col-lg-6 mb-3">
                        <label class="form-label">Policy Number (Optional)</label>
                        <Field
                          type="text"
                          id="policy_number"
                          class="form-control"
                          v-model="orderData.policy_number"
                          name="policy_number"
                          placeholder="Enter Policy Number "
                          :validateOnInput="true"
                        />
                      </div>
                      <div class="col-lg-6 mb-3">
                        <label class="form-label">Insurance expiry date (Optional)</label>

                        <Field
                          name="insurance_expiry_date"
                          id="insurance_expiry_date"
                          v-slot="{ field }"
                          v-model="orderData.insurance_expiry_date"
                        >
                          <Datepicker
                            autoApply
                            v-model="orderData.insurance_expiry_date"
                            inputFormat="dd-MM-yyyy"
                            format="yyyy-MM-dd"
                            placeholder="Select Date"
                            v-bind="field"
                            :timePicker="false"
                          />
                        </Field>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-borderless table-bordered mb-4">
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th style="min-width: 180px">Description</th>
                    <th>HSN/SAC</th>
                    <th style="min-width: 70px">Qty</th>
                    <th style="min-width: 90px">Rate</th>
                    <th>Total</th>
                    <th width="130">Discount Percentage</th>
                    <th width="150">Discount Price</th>
                    <th>Taxable Value</th>

                    <th colspan="2" class="ml-4" width="400">CGST</th>
                    <th colspan="2" width="400">SGST</th>

                    <th colspan="2" width="400">IGST</th>
                    <th width="100">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item, index) in ordeServiceList.filter(
                      (item) =>
                        item.type === 'SERVICE' ||
                        item.type === 'INVENTORY' ||
                        item.type === 'OTHER_SERVICE'
                    )"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td v-if="item.type == 'INVENTORY'">
                      <Field
                        v-slot="{ field }"
                        :name="'inventory_master_id_' + index"
                        :rules="item.is_required == 'Y' ? 'required:Inventory,true' : ''"
                        :validateOnInput="true"
                        v-model="item.inventory_master_id"
                      >
                        <select
                          id="inventory_master_id"
                          class="form-select"
                          v-model="item.inventory_master_id"
                          :name="'inventory_master_id_' + index"
                          :validateOnInput="true"
                          v-bind="field"
                          @change="checkSelection(item.index_value)"
                        >
                          <option value="">Select Inventory</option>
                          <option
                            v-for="ite in inventoryData"
                            :key="ite.id"
                            :value="ite.id"
                            :label="ite.part_description + ' - ' + ite.part_number"
                          >
                            {{ ite.part_description }} - {{ ite.part_number }}
                          </option>
                        </select>
                      </Field>
                      <ErrorMessage
                        :name="'inventory_master_id_' + index"
                        component="span"
                        class="text-danger"
                      />
                    </td>
                    <td v-if="item.type == 'SERVICE'">{{ item.title }}</td>

                    <td v-if="item.type == 'OTHER_SERVICE'">
                      <Field
                        type="text"
                        id="title"
                        class="form-control"
                        v-model="item.title"
                        :name="`title` + index + index"
                        placeholder="Enter title "
                        :validateOnInput="true"
                        v-on:change="HanndleTitleValidation(item.index_value)"
                      />
                      <ErrorMessage
                        :name="`title` + index + index"
                        component="span"
                        class="text-danger"
                      />
                    </td>
                    <td>{{ item.sac_code }}</td>
                    <td>
                      <Field
                        type="text"
                        class="form-control"
                        :name="'qunatity' + index + index"
                        min="1"
                        :rules="
                          item.is_required == 'Y' && item.title
                            ? 'required:qunatity|minValue:0'
                            : ''
                        "
                        v-on:keypress="$helperService.allowOnlyNumericValue($event)"
                        :validateOnInput="true"
                        v-model="item.qunatity"
                        v-on:change="updateTotal(item.index_value, 'QUANTITY')"
                      />
                      <ErrorMessage
                        :name="'qunatity' + index + index"
                        class="validation-msg mt-1"
                      />
                    </td>
                    <td>
                      <Field
                        type="text"
                        class="form-control"
                        :name="'rate' + index + index"
                        min="1"
                        :rules="
                          item.is_required == 'Y' && item.title
                            ? 'required:rate|minValue:0'
                            : ''
                        "
                        v-on:keypress="$helperService.allowOnlyNumericValue($event)"
                        :validateOnInput="true"
                        v-model="item.rate"
                        v-on:change="updateTotal(item.index_value, 'RATE')"
                      />
                      <ErrorMessage
                        :name="'rate' + index + index"
                        class="validation-msg mt-1"
                      />
                    </td>
                    <td>{{ item.total }}</td>
                    <td>
                      <div class="d-flex gap-2 align-items-center">
                        <Field
                          type="text"
                          class="form-control"
                          :name="'discount_percentage' + index + index"
                          :rules="item.title ? 'maxValue' : ''"
                          v-on:keypress="
                            $helperService.allowOnlyNumericValueUpto3($event)
                          "
                          :validateOnInput="true"
                          v-model="item.discount_percentage"
                          v-on:change="
                            updateTotal(item.index_value, 'DISCOUNT_PERCENTAGE')
                          "
                        />
                        %
                      </div>

                      <ErrorMessage
                        :name="'discount_percentage' + index + index"
                        class="validation-msg mt-1"
                      />
                    </td>
                    <td>
                      <Field
                        type="text"
                        class="form-control"
                        :name="'discount_price' + index + index"
                        v-on:keypress="$helperService.allowOnlyNumericValue($event)"
                        :rules="item.title ? 'discountPrice:' + item.total : ''"
                        :validateOnInput="true"
                        v-model="item.discount_price"
                        v-on:change="updateTotal(item.index_value, 'DISCOUNT_PRICE')"
                      />
                      <ErrorMessage
                        :name="'discount_price' + index + index"
                        class="validation-msg mt-1"
                      />
                    </td>
                    <td>{{ item.taxable_value }}</td>
                    <td>
                      <Field
                        type="text"
                        class="form-control"
                        style="width: 50px"
                        :name="'cgst_percentage' + index"
                        :disabled="orderData.stateObj.type == 'INTER'"
                        min="1"
                        max="100"
                        v-on:keypress="$helperService.allowOnlyNumericValue($event)"
                        :validateOnInput="true"
                        v-model="item.cgst_percentage"
                        v-on:change="updateTotal(item.index_value, 'GST')"
                      />
                      <ErrorMessage
                        :name="'cgst_percentage' + index"
                        class="validation-msg mt-1"
                      />%
                    </td>
                    <td>
                      {{ $helperService.getTwoDecimalValue(item.cgst) }}
                    </td>
                    <td>
                      <Field
                        type="text"
                        class="form-control"
                        style="width: 50px"
                        :name="'sgst_percentage' + index"
                        :disabled="orderData.stateObj.type == 'INTER'"
                        min="1"
                        max="100"
                        v-on:keypress="$helperService.allowOnlyNumericValue($event)"
                        :validateOnInput="true"
                        v-model="item.sgst_percentage"
                        v-on:change="updateTotal(item.index_value, 'GST')"
                      />
                      <ErrorMessage
                        :name="'sgst_percentage' + index"
                        class="validation-msg mt-1"
                      />%
                    </td>
                    <td>
                      {{ $helperService.getTwoDecimalValue(item.sgst) }}
                    </td>
                    <td>
                      <Field
                        type="text"
                        class="form-control"
                        style="width: 50px"
                        :name="'igst_percentage' + index"
                        :disabled="orderData.stateObj.type == 'INTRA'"
                        min="1"
                        max="100"
                        v-on:keypress="$helperService.allowOnlyNumericValue($event)"
                        :validateOnInput="true"
                        v-model="item.igst_percentage"
                        v-on:change="updateTotal(item.index_value, 'GST')"
                      />
                      <ErrorMessage
                        :name="'igst_percentage' + index"
                        class="validation-msg mt-1"
                      />%
                    </td>

                    <td>
                      {{ $helperService.getTwoDecimalValue(item.igst) }}
                    </td>

                    <td v-if="item.type == 'OTHER_SERVICE'">
                      <a
                        v-if="item.state !== `INITIAL`"
                        @click="handleRemoveinput('OTHER_SERVICE', item.index_value)"
                        ><img
                          src="static/images/close-icon.svg"
                          width="20"
                          class="me-1"
                          alt="d"
                      /></a>
                      <a
                        v-if="item.state === `INITIAL`"
                        @click="hanldeOtherPlusInput('OTHER_SERVICE')"
                        class="ms-2"
                      >
                        <button
                          type="button"
                          class="btn btn-primary btn-sm p-2"
                          style="height: 32px; line-height: 14px; width: 32px"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-plus-lg"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                            />
                          </svg>
                        </button>
                      </a>
                    </td>
                    <td v-if="item.type == 'INVENTORY'">
                      <a
                        v-if="item.state !== `INITIAL`"
                        @click="handleRemoveinput('INVENTORY', item.index_value)"
                        class="cursor-pointer"
                        ><img
                          src="static/images/close-icon.svg"
                          width="20"
                          class="me-1"
                          alt=""
                      /></a>
                      <a
                        v-if="item.state === `INITIAL`"
                        @click="hanldeOtherPlusInput('INVENTORY')"
                        class="ms-2"
                      >
                        <button
                          type="button"
                          class="btn btn-primary btn-sm p-2"
                          style="height: 32px; line-height: 14px; width: 32px"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-plus-lg"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                            />
                          </svg>
                        </button>
                      </a>
                    </td>
                    <td v-else></td>
                  </tr>
                </tbody>
                <tr>
                  <th></th>
                  <th colspan="15">Labour Description</th>
                </tr>

                <tbody>
                  <tr
                    v-for="(item, index) in ordeServiceList.filter(
                      (item) => item.type === 'OTHER' || item.type === 'OTHER_OTHER'
                    )"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td v-if="item.type == 'OTHER_OTHER'">
                      <Field
                        type="text"
                        id="title"
                        class="form-control"
                        v-model="item.title"
                        :name="`title` + index"
                        placeholder="Enter Title  "
                        :validateOnInput="true"
                        v-on:change="HanndleTitleValidation(item.index_value)"
                      />
                      <ErrorMessage
                        :name="`title` + index"
                        component="span"
                        class="text-danger"
                      />
                    </td>
                    <td v-if="item.type == `OTHER`">{{ item.title }}</td>

                    <td>{{ item.sac_code }}</td>
                    <td>
                      <Field
                        type="text"
                        class="form-control"
                        :name="'qunatity' + index"
                        min="1"
                        :rules="
                          item.is_required == 'Y' && item.title
                            ? 'required:qunatity|minValue:0'
                            : ''
                        "
                        v-on:keypress="$helperService.allowOnlyNumericValue($event)"
                        :validateOnInput="true"
                        v-model="item.qunatity"
                        v-on:change="updateTotal(item.index_value, `QUANTITY`)"
                      />
                      <ErrorMessage
                        :name="'qunatity' + index"
                        class="validation-msg mt-1"
                      />
                    </td>
                    <td>
                      <Field
                        type="text"
                        class="form-control"
                        :name="'rate' + index"
                        min="1"
                        :rules="
                          item.is_required == 'Y' && item.title
                            ? 'required:rate|minValue:0'
                            : ''
                        "
                        v-on:keypress="$helperService.allowOnlyNumericValue($event)"
                        :validateOnInput="true"
                        v-model="item.rate"
                        v-on:change="updateTotal(item.index_value, `RATE`)"
                      />
                      <ErrorMessage :name="'rate' + index" class="validation-msg mt-1" />
                    </td>
                    <td>{{ item.total }}</td>
                    <td>
                      <div class="d-flex gap-2 align-items-center">
                        <Field
                          type="text"
                          class="form-control"
                          :name="'discount_percentage_' + index"
                          :rules="item.title ? 'maxValue' : ''"
                          v-on:keypress="
                            $helperService.allowOnlyNumericValueUpto3($event)
                          "
                          :validateOnInput="true"
                          v-model="item.discount_percentage"
                          v-on:change="
                            updateTotal(item.index_value, `DISCOUNT_PERCENTAGE`)
                          "
                        />
                        %
                      </div>
                      <ErrorMessage
                        :name="'discount_percentage_' + index"
                        class="validation-msg mt-1"
                      />
                    </td>
                    <td>
                      <Field
                        type="text"
                        class="form-control"
                        :name="'discount_price' + index"
                        :rules="item.title ? 'discountPrice:' + item.total : ''"
                        @input="checkMax(item.discount_price, item.total)"
                        :validateOnInput="true"
                        v-model="item.discount_price"
                        v-on:change="updateTotal(item.index_value, `DISCOUNT_PRICE`)"
                      />
                      <ErrorMessage
                        :name="'discount_price' + index"
                        class="validation-msg mt-1"
                      />
                    </td>
                    <td>{{ item.taxable_value }}</td>
                    <td>
                      <Field
                        type="text"
                        class="form-control"
                        :name="'cgst_percentage_' + index"
                        :disabled="orderData.stateObj.type == 'INTER'"
                        min="1"
                        max="100"
                        v-on:keypress="$helperService.allowOnlyNumericValue($event)"
                        :validateOnInput="true"
                        v-model="item.cgst_percentage"
                        v-on:change="updateTotal(item.index_value, 'GST')"
                      />
                      <ErrorMessage
                        :name="'cgst_percentage_' + index"
                        class="validation-msg mt-1"
                      />%
                    </td>
                    <td>
                      {{ $helperService.getTwoDecimalValue(item.cgst) }}
                    </td>
                    <td>
                      <Field
                        type="text"
                        class="form-control"
                        :name="'sgst_percentage_' + index"
                        :disabled="orderData.stateObj.type == 'INTER'"
                        min="1"
                        max="100"
                        v-on:keypress="$helperService.allowOnlyNumericValue($event)"
                        :validateOnInput="true"
                        v-model="item.sgst_percentage"
                        v-on:change="updateTotal(item.index_value, 'GST')"
                      />
                      <ErrorMessage
                        :name="'sgst_percentage_' + index"
                        class="validation-msg mt-1"
                      />%
                    </td>
                    <td>
                      {{ $helperService.getTwoDecimalValue(item.sgst) }}
                    </td>
                    <td>
                      <Field
                        type="text"
                        class="form-control"
                        :name="'igst_percentage_' + index"
                        :disabled="orderData.stateObj.type == 'INTRA'"
                        min="1"
                        max="100"
                        v-on:keypress="$helperService.allowOnlyNumericValue($event)"
                        :validateOnInput="true"
                        v-model="item.igst_percentage"
                        v-on:change="updateTotal(item.index_value, 'GST')"
                      />
                      <ErrorMessage
                        :name="'igst_percentage_' + index"
                        class="validation-msg mt-1"
                      />%
                    </td>

                    <td>
                      {{ $helperService.getTwoDecimalValue(item.igst) }}
                    </td>

                    <td v-if="item.type == 'OTHER_OTHER'">
                      <a
                        v-if="item.state !== `INITIAL`"
                        @click="handleRemoveinput(`OTHER_OTHER`, item.index_value)"
                        ><img
                          src="static/images/close-icon.svg"
                          width="20"
                          class="me-1"
                          alt=""
                      /></a>
                      <a @click="hanldeOtherPlusInput(`OTHER_OTHER`)" class="ms-2">
                        <button
                          type="button"
                          class="btn btn-primary btn-sm p-2"
                          style="height: 32px; line-height: 14px; width: 32px"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-plus-lg"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                            />
                          </svg>
                        </button>
                      </a>
                    </td>
                    <td v-else></td>
                  </tr>

                  <tr>
                    <td></td>
                    <td class="font-bold" colspan="4">Total</td>

                    <td class="font-bold">
                      {{ this.serviceTotal }}
                    </td>
                    <td></td>
                    <td class="font-bold">
                      {{ this.ServiceDiscountTotal }}
                    </td>
                    <td class="font-bold">
                      {{ this.serviceTaxTotal }}
                    </td>
                    <td></td>
                    <td class="font-bold">
                      {{ this.CgstServiceTotal }}
                    </td>
                    <td></td>
                    <td class="font-bold">
                      {{ this.SgstServiceTotal }}
                    </td>
                    <td></td>
                    <td class="font-bold">
                      {{ this.IgstServiceTotal }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- <div class="card bg-light p-3 border">
            <div class="font-18 font-bold border-bottom pb-2 mb-3">
              Payment Details
            </div>
            <div class="row">
              <div class="col-lg-6">
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <th>Invoice Value</th>
                      <td>{{ orderData.invoice_value }}</td>
                    </tr>
                    <tr>
                      <th>Coupon, Amount</th>
                      <td>{{ orderData.coupon_amount }}</td>
                    </tr>
                    <tr>
                      <th>Rewards</th>
                      <td>{{ orderData.reward_amount }}</td>
                    </tr>
                    <tr>
                      <th>Advance Paid</th>
                      <td>{{ orderData.advanced_paid }}</td>
                    </tr>
                    <tr>
                      <th>Payable Amount</th>
                      <td>{{ orderData.payable_amount }}</td>
                    </tr>
                    <tr>
                      <th>CGST</th>
                      <td>{{ orderData.cgst }}</td>
                    </tr>
                    <tr>
                      <th>SGST</th>
                      <td>{{ orderData.sgst }}</td>
                    </tr>
                    <tr>
                      <th>IGST</th>
                      <td>{{ orderData.igst }}</td>
                    </tr>
                    <tr>
                      <th>Paid Amount</th>
                      <td>{{ orderData.paid_amount }}</td>
                    </tr>
                    <tr>
                      <th>Mode of payment</th>
                      <td></td>
                    </tr>
                    <tr>
                      <th>Transaction ID</th>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <p class="font-14">
                  Note : Value of RS 2600 pertains to services provided by xyz
                  garage
                </p>
              </div>
              <div class="col-lg-6 text-end">
                <p class="font-bold mb-2 font-18">Vioron Internet Pvt Ltd</p>
                <p class="mb-0 font-medium">Address :</p>
                <p class="mb-0">Off No.402 4 Floor, Vikram Goldmine,</p>
                <p class="mb-2">FC Rd Shivajinagar Pune, 411005</p>
                <p class="mb-0">GSTIN: 27AAICV2194D1Z8</p>
              </div>
            </div>
          </div> -->

            <div class="col-12 text-center border-top pt-3 mb-5">
              <button id="save-data" class="btn btn-primary px-5" native-type="submit">
                {{
                  type == "GENERATE_ESTIMATE" || type == "REVICE_ESTIMATE"
                    ? "Send Estimate"
                    : "Send Proforma"
                }}
              </button>
            </div>
          </Form>
        </div>
      </div>
      <ErrorComponent ref="errorComponent" />
    </div>
  </div>
</template>
<script>
// import "./onboarding.scss";
import { Form, Field, ErrorMessage } from "vee-validate";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import ErrorComponent from "@/components/ErrorComponent";
export default {
  name: "InvoicePage",
  components: {
    Form,
    Field,
    ErrorMessage,
    Datepicker,
    ErrorComponent,
  },

  data() {
    return {
      // type: "GENERATE_ESTIMATE",
      // id: "/3fe67ca1-929a-4176-a608-c6df869ad697/GENERATE_ESTIMATE",
      type: this.$route.params.type,
      id: this.$route.params.id,
      orderData: {
        job_type: null,
        insurance_id: null,
      },
      insuranceListOption: [],
      inventoryData: [],
      serviceTotal: "",
      ServiceDiscountTotal: "",
      serviceTaxTotal: ``,
      CgstServiceTotal: ``,
      IgstServiceTotal: ``,
      SgstServiceTotal: ``,
      isRequired: false,
      ordeServiceList: [],
    };
  },

  mounted() {
    this.getOrderEstimateData();
    this.getInsuranceList();
    this.getInventoryList();
    localStorage.removeItem("next_url");
  },
  methods: {
    checkMax(value, totalValue) {
      if (value > totalValue) {
        return totalValue;
      }
    },
    getOrderEstimateData() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }

      this.$api
        .getAPI({
          _action: `order/estimate/${this.id}/${this.type}`,
        })
        .then((res) => {
          this.orderData = res.info;
          var i = 0;
          res.info.services.forEach((service) => {
            service.index_value = i;
            if (service.title == "Pick Up" || service.title == "Drop") {
              service.is_required = "N";
            } else {
              service.is_required = "Y";
            }

            if (!service.cgst) {
              service.cgst = 0;
            }
            if (!service.sgst) {
              service.sgst = 0;
            }
            if (!service.igst) {
              service.igst = 0;
            }
            this.ordeServiceList.push(service);
            i++;
          });

          this.hanldeOtherPlusInput("INVENTORY", "INITIAL");
          this.hanldeOtherPlusInput("OTHER_OTHER", "INITIAL");
          this.hanldeOtherPlusInput("OTHER_SERVICE", "INITIAL");

          setTimeout(() => {
            this.ongetCaluclation();
          }, 2500);

          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        })

        .catch(() => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        });
    },
    getInsuranceList() {
      this.$api
        .getAPI({
          _action: `insurance/${this.id} `,
        })
        .then((res) => {
          this.insuranceListOption = res.list;
        })
        .catch(() => {});
    },
    getInventoryList() {
      this.$api
        .getAPI({
          _action: `inventory/${this.id} `,
        })
        .then((res) => {
          this.inventoryData = res.list;
        })
        .catch(() => {});
    },
    handleOnSave() {
      let NewServiceList = [];
      this.orderData.invoice_value = this.serviceTotal;
      this.orderData.discounted_value = this.ServiceDiscountTotal;
      this.orderData.cgst = this.CgstServiceTotal;
      this.orderData.igst = this.IgstServiceTotal;
      this.orderData.sgst = this.SgstServiceTotal;
      this.orderData.paid_amount = this.serviceTaxTotal;
      for (let j = 0; j < this.ordeServiceList.length; j++) {
        if (
          this.ordeServiceList[j].type == "INVENTORY" &&
          this.ordeServiceList[j].inventory_master
        ) {
          this.ordeServiceList[j].title =
            this.ordeServiceList[j].inventory_master.part_description +
            " " +
            this.ordeServiceList[j].inventory_master.part_number;
        }
        if (
          this.ordeServiceList[j].qunatity &&
          this.ordeServiceList[j].qunatity > 0 &&
          this.ordeServiceList[j].rate > 0
        ) {
          NewServiceList.push(this.ordeServiceList[j]);
        }
      }

      const obj = {
        ...this.orderData,
        services: NewServiceList,
      };

      this.$api
        .postAPI({
          _action: `order/estimate/${this.id}`,
          _body: obj,
          _buttonId: "save-data",
        })
        .then((res) => {
          this.$toast.open({
            message: res.message,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.$router.push(`/order-details/${this.id}`);
        })
        .catch(() => {});
    },

    updateTotal(index, type) {
      let TotalServiceCalculatedSum = 0;
      let TotalServiceDiscountPrice = 0;
      let TotalServiceTax = 0;
      let TotalServiceCgst = 0;
      let TotalServiceSgst = 0;
      let TotalServiceIgst = 0;
      setTimeout(() => {
        for (let i = 0; i < this.ordeServiceList.length; i++) {
          let item = this.ordeServiceList[i];

          if (item.rate && item.qunatity) {
            item.total = item.qunatity * item.rate;
          }

          if (item.total && item.discount_price) {
            item.taxable_value = item.total - item.discount_price;
          } else {
            item.taxable_value = item.total;
          }
          if (
            item.total &&
            item.discount_percentage &&
            (type == "DISCOUNT_PERCENTAGE" || type == "QUANTITY" || type == "RATE")
          ) {
            item.discount_price = (item.total * item.discount_percentage) / 100;
          }
          if (
            item.total &&
            item.discount_price &&
            (type == "DISCOUNT_PRICE" || type == "QUANTITY" || type == "RATE")
          ) {
            item.discount_percentage = (item.discount_price / item.total) * 100;
          }

          if (item.taxable_value) {
            item.taxable_value = item.total - item.discount_price;

            if (this.orderData.stateObj.type == "INTRA") {
              if (item.type == "INVENTORY") {
                item.igst = 0;
                item.igst_percentage = 0;
                item.sgst = item.taxable_value * (item.igst_percentage / 100);
                item.cgst = item.taxable_value * (item.cgst_percentage / 100);
              } else {
                item.igst = 0;
                item.igst_percentage = 0;
                item.sgst =
                  item.taxable_value *
                  ((item.sgst_percentage
                    ? item.sgst_percentage
                    : this.orderData.stateObj.sgst_percentage) /
                    100);
                item.sgst_percentage = item.sgst_percentage
                  ? item.sgst_percentage
                  : this.orderData.stateObj.sgst_percentage;

                item.cgst =
                  item.taxable_value *
                  ((item.cgst_percentage
                    ? item.cgst_percentage
                    : this.orderData.stateObj.cgst_percentage) /
                    100);
                item.cgst_percentage = item.cgst_percentage
                  ? item.cgst_percentage
                  : this.orderData.stateObj.cgst_percentage;
              }
            } else {
              if (item.type == "INVENTORY") {
                item.igst = item.taxable_value * (item.igst_percentage / 100);
                item.sgst = 0;
                item.sgst_percentage = 0;
                item.cgst = 0;
                item.cgst_percentage = 0;
              } else {
                item.igst =
                  item.taxable_value *
                  ((item.igst_percentage
                    ? item.igst_percentage
                    : this.orderData.stateObj.igst_percentage) /
                    100);
                item.igst_percentage = item.igst_percentage
                  ? item.igst_percentage
                  : this.orderData.stateObj.igst_percentage;
                item.sgst = 0;
                item.sgst_percentage = 0;
                item.cgst = 0;
                item.cgst_percentage = 0;
              }
            }
          }

          TotalServiceCalculatedSum += this.ordeServiceList[i].total;
          if (this.ordeServiceList[i].discount_price > 0) {
            TotalServiceDiscountPrice += Number(this.ordeServiceList[i].discount_price);
          }

          if (this.ordeServiceList[i].taxable_value > 0) {
            TotalServiceTax += this.ordeServiceList[i].taxable_value;
          }

          TotalServiceCgst += this.ordeServiceList[i].cgst;
          TotalServiceSgst += this.ordeServiceList[i].sgst;
          TotalServiceIgst += this.ordeServiceList[i].igst;

          this.serviceTotal = TotalServiceCalculatedSum;
          this.ServiceDiscountTotal = TotalServiceDiscountPrice;
          this.serviceTaxTotal = TotalServiceTax;
          this.CgstServiceTotal = TotalServiceCgst;
          this.SgstServiceTotal = TotalServiceSgst;
          this.IgstServiceTotal = TotalServiceIgst;
        }
      }, 1000);
    },

    ongetCaluclation() {
      let TotalOtherCalculatedSum = 0;
      let TotalOtherDiscountPrice = 0;
      let TotalOtherTax = 0;
      let TotalOtherCgst = 0;
      let TotalOtherSgst = 0;
      let TotalOtherIgst = 0;

      for (let i = 0; i < this.ordeServiceList.length; i++) {
        let item = this.ordeServiceList[i];
        if (item.qunatity) {
          item.total = item.qunatity * item.rate;
        }
        if (item.discount_price) {
          item.discount_percentage = (item.discount_price / item.total) * 100;
        }
        if (item.discount_percentage) {
          item.discount_price = item.total * (item.discount_percentage / 100);
        }
        if (item.total && item.discount_price) {
          item.taxable_value = item.total - item.discount_price;
        } else {
          item.taxable_value = item.total;
        }

        if (item.taxable_value) {
          if (this.orderData.stateObj.type == "INTRA") {
            if (item.type == "INVENTORY") {
              item.igst = 0;
              item.igst_percentage = 0;
              item.sgst = item.taxable_value * (item.inventory_master.sgst / 100);
              item.sgst_percentage = item.inventory_master.sgst;

              item.cgst = item.taxable_value * (item.inventory_master.cgst / 100);
              item.cgst_percentage = item.inventory_master.cgst;
            } else {
              item.igst = 0;
              item.igst_percentage = 0;
              item.sgst =
                item.taxable_value *
                ((item.sgst_percentage
                  ? item.sgst_percentage
                  : this.orderData.stateObj.sgst_percentage) /
                  100);
              item.sgst_percentage = item.sgst_percentage
                ? item.sgst_percentage
                : this.orderData.stateObj.sgst_percentage;

              item.cgst =
                item.taxable_value *
                ((item.cgst_percentage
                  ? item.cgst_percentage
                  : this.orderData.stateObj.cgst_percentage) /
                  100);
              item.cgst_percentage = item.cgst_percentage
                ? item.cgst_percentage
                : this.orderData.stateObj.cgst_percentage;
            }
          } else {
            if (item.type == "INVENTORY") {
              console.log(item.inventory_master);
              item.igst = item.taxable_value * (item.inventory_master.igst / 100);
              item.igst_percentage = item.inventory_master.igst;
              item.sgst = 0;
              item.sgst_percentage = 0;
              item.cgst = 0;
              item.cgst_percentage = 0;
            } else {
              item.igst =
                item.taxable_value *
                ((item.igst_percentage
                  ? item.igst_percentage
                  : this.orderData.stateObj.igst_percentage) /
                  100);
              item.igst_percentage = item.igst_percentage
                ? item.igst_percentage
                : this.orderData.stateObj.igst_percentage;
              item.sgst = 0;
              item.sgst_percentage = 0;
              item.cgst = 0;
              item.cgst_percentage = 0;
            }
          }
        }
        TotalOtherCalculatedSum += this.ordeServiceList[i].total;
        if (this.ordeServiceList[i].discount_price > 0) {
          TotalOtherDiscountPrice += this.ordeServiceList[i].discount_price;
        }

        if (this.ordeServiceList[i].taxable_value > 0) {
          TotalOtherTax += this.ordeServiceList[i].taxable_value;
        }

        TotalOtherCgst += this.ordeServiceList[i].cgst;
        TotalOtherSgst += this.ordeServiceList[i].sgst;
        TotalOtherIgst += this.ordeServiceList[i].igst;

        this.serviceTotal = TotalOtherCalculatedSum;
        this.ServiceDiscountTotal = TotalOtherDiscountPrice;
        this.serviceTaxTotal = TotalOtherTax;
        this.CgstServiceTotal = TotalOtherCgst;
        this.SgstServiceTotal = TotalOtherSgst;
        this.IgstServiceTotal = TotalOtherIgst;
      }
    },

    hanldeOtherPlusInput(type, state) {
      var obj = {
        index_value: this.ordeServiceList.length,
        state: state === "INITIAL" ? "INITIAL" : null,
        is_required: "N",
        title: "",
        inventory_master_id: "",
        type: type,
        sac_code: "",
        qunatity: 1,
        rate: 0,
        total: 0,
        discount_percentage: 0,
        discount_price: 0,
        taxable_value: 0,
        cgst_percentage: 0,
        cgst: 0,
        sgst_percentage: 0,
        sgst: 0,
        igst_percentage: 0,
        igst: 0,
      };

      if (type == "OTHER_OTHER") {
        this.ordeServiceList.push(obj);
      } else if (type == "INVENTORY") {
        this.ordeServiceList.push(obj);
      } else {
        this.ordeServiceList.push(obj);
      }
    },
    handleRemoveinput(type, index) {
      if (type == "OTHER_OTHER") {
        this.ordeServiceList.splice(index, 1);
      } else if (type == "INVENTORY") {
        this.ordeServiceList.splice(index, 1);
      } else {
        this.ordeServiceList.splice(index, 1);
      }
    },
    checkSelection(i) {
      setTimeout(() => {
        console.log(i);
        console.log(this.ordeServiceList);
        if (this.ordeServiceList[i].type == "INVENTORY") {
          if (!this.ordeServiceList[i].inventory_master_id) {
            this.ordeServiceList[i].is_required = "N";
            this.ordeServiceList[i].qunatity = 1;
            this.ordeServiceList[i].rate = 0;
            this.ordeServiceList[i].total = 0;
            this.ordeServiceList[i].discount_percentage = 0;
            this.ordeServiceList[i].discount_price = 0;
            this.ordeServiceList[i].taxable_value = 0;
            this.ordeServiceList[i].cgst = 0;
            this.ordeServiceList[i].cgst_percentage = 0;
            this.ordeServiceList[i].sgst = 0;
            this.ordeServiceList[i].sgst_percentage = 0;
            this.ordeServiceList[i].igst = 0;
            this.ordeServiceList[i].igst_percentage = 0;
          } else {
            for (var j = 0; j < this.inventoryData.length; j++) {
              if (this.ordeServiceList[i].inventory_master_id) {
                if (
                  this.ordeServiceList[i].inventory_master_id == this.inventoryData[j].id
                ) {
                  this.ordeServiceList[i].is_required = "Y";
                  this.ordeServiceList[i].qunatity = 1;
                  this.ordeServiceList[i].inventory_master = this.inventoryData[j];
                  this.ordeServiceList[i].inventory_master_id = this.inventoryData[j].id;
                  this.ordeServiceList[i].rate = this.inventoryData[j].rate;
                  this.ordeServiceList[i].cgst = this.inventoryData[j].cgst;
                  this.ordeServiceList[i].sgst = this.inventoryData[j].sgst;
                  this.ordeServiceList[i].igst = this.inventoryData[j].igst;
                  this.ordeServiceList[i].taxable_value = this.inventoryData[j].rate;

                  if (this.orderData.stateObj.type == "INTRA") {
                    this.ordeServiceList[i].igst = 0;
                    this.ordeServiceList[i].igst_percentage = 0;
                    this.ordeServiceList[i].sgst =
                      this.ordeServiceList[i].taxable_value *
                      ((this.inventoryData[j].sgst
                        ? this.inventoryData[j].sgst
                        : this.orderData.stateObj.sgst_percentage) /
                        100);
                    this.ordeServiceList[i].sgst_percentage = this.inventoryData[j].sgst;
                    this.ordeServiceList[i].cgst =
                      this.ordeServiceList[i].taxable_value *
                      ((this.inventoryData[j].cgst
                        ? this.inventoryData[j].cgst
                        : this.orderData.stateObj.cgst_percentage) /
                        100);
                    this.ordeServiceList[i].cgst_percentage = this.inventoryData[j].cgst;
                  } else {
                    this.ordeServiceList[i].igst =
                      this.ordeServiceList[i].taxable_value *
                      ((this.inventoryData[j].igst
                        ? this.inventoryData[j].igst
                        : this.orderData.stateObj.igst_percentage) /
                        100);
                    this.ordeServiceList[i].igst_percentage = this.inventoryData[j].igst;
                    this.ordeServiceList[i].sgst = 0;
                    this.ordeServiceList[i].sgst_percentage = 0;
                    this.ordeServiceList[i].cgst = 0;
                    this.ordeServiceList[i].cgst_percentage = 0;
                  }
                }
              }
            }
          }
        }
        this.ongetCaluclation();
      }, 1000);
    },

    HanndleTitleValidation(index) {
      for (var i = 0; i < this.ordeServiceList.length; i++) {
        if (this.ordeServiceList[i].index_value == index)
          this.ordeServiceList[i].is_required = "Y";
      }
    },

    checkInsurance() {
      this.orderData.insurance_state_code = "";
      this.orderData.insurance_state = "";
      this.orderData.insurance_address = "";
      for (var i = 0; i < this.insuranceListOption.length; i++) {
        if (this.insuranceListOption[i].id == this.orderData.insurance_id) {
          if (this.insuranceListOption[i].insurance_state) {
            this.orderData.insurance_state_code = this.insuranceListOption[
              i
            ].insurance_state.gst_code;
            this.orderData.insurance_state = this.insuranceListOption[
              i
            ].insurance_state.name;
            this.orderData.insurance_address = this.insuranceListOption[
              i
            ].insurance_state.address_line_1;
          }
        }
      }
    },

    checkVehicleRegistrationNumber() {
      this.$api
        .postAPI({
          _action: `check-vehicle-registration-estimate`,
          _body: { vehicle_registration_number: this.orderData.vehicle_number },
        })
        .then((res) => {
          if (res) {
            this.orderData.chassis_number = res.chassis_no;
            this.orderData.engine_number = res.engine_number;
            this.orderData.vehicle_registration_date = res.reg_date;
            this.orderData.insurance_expiry_date = res.insurance_expiry_date;
            this.orderData.policy_number = res.policy_number;
          }
        })

        .catch(() => {});
    },
  },
};
</script>

