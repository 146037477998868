import moment from "moment";
import WebService from "./WebService";
import Store from "./StoreService";

String.prototype.toProperCase = function () {
  return this.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

class HelperService {
  sorting(sortBy, id) {
    var orderBy = "ASC";
    var className = "sorting_asc";
    var temp = document.getElementById(id);
    if (temp) {
      if (temp.className.includes("sorting_asc")) {
        className = "sorting_desc";
      }
      if (className == "sorting_asc") {
        temp.classList.add("sorting_asc");
        temp.classList.remove("sorting_desc");
        orderBy = "ASC";
      } else if (className == "sorting_desc") {
        temp.classList.add("sorting_desc");
        temp.classList.remove("sorting_asc");
        orderBy = "DESC";
      }
    }
    var obj = {
      order_by: orderBy,
      sort_by: sortBy,
    };
    return obj;
  }

  // getFormattedDate(d) {
  //     return moment(d).format("MMM DD, YYYY  hh:mm A");
  // }

  getFormattedTime(t) {
    return moment(t).format("hh:mm A");
  }

  getFormattedDateOnly(dt) {
    return moment.utc(dt).local().format("MMM DD, YYYY");
    // return moment(dt).format("DD/MM/YYYY hh:mm A");
  }

  getFormattedDateDDMMMMYY(dt) {
    return moment.utc(dt).local().format("DD MMMM YYYY");
    // return moment(dt).format("DD/MM/YYYY hh:mm A");
  }

  getFormattedDate(dt) {
    if (dt == null || dt == "" || dt == undefined) {
      return "";
    }
    return moment.utc(dt).local().format("MMM DD, YYYY  hh:mm A");
    // return moment(dt).format("DD/MM/YYYY hh:mm A");
  }

  getOrderDetailFormattedDate(dt) {
    if (dt == null || dt == "" || dt == undefined) {
      return "";
    }
    return moment.utc(dt).local().format("DD MMM YYYY,  hh:mm A");
    // return moment(dt).format("DD/MM/YYYY hh:mm A");
  }

  getTwoDecimalValue(dt) {
    if (isNaN(dt)) {
      return dt;
    }
    return parseFloat(dt).toFixed(2);
  }

  sendFormattedDate(d, type) {
    if (type == "date") {
      return moment(d).format("YYYY-MM-DD");
    } else if (type == "month_year") {
      return moment(d).format("YYYY-MM");
    } else if (type == "year") {
      return moment(d).format("YYYY");
    } else {
      return moment(d).format("YYYY-MM-DD");
    }
  }

  sendFormattedDateDMY(d) {
    return moment(d).format("DD-MM-YYYY");
  }

  allowOnlyNumericValue(e) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode != 8) {
      e.preventDefault();
      return false;
    }
  }

  allowOnlyNumericValueUpto3(e) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode != 8) {
      e.preventDefault();
      return false;
    }

    if (e.currentTarget.value.length > 2) {
      e.preventDefault();
      return false;
    }
  }

  convertUnderscore(value) {
    if (!value) {
      return "";
    }

    let pattern = /_/gi;
    let replacement = " ";
    let result = value.replaceAll(pattern, replacement);
    let res = result.toProperCase();

    pattern = / /gi;
    replacement = "-";
    return res.replaceAll(pattern, replacement);
  }

  getOrder(value) {
    if (!value) {
      return "";
    }

    if (value == 'RESCHEDULED_BY_VENDOR' || value == 'RESCHEDULED_BY_CUSTOMER') {
      return "Rescheduled";
    } else if (value == 'PENDING') {
      return this.getTitleCase(value);
    } else if (value == 'CANCELLED') {
      return this.getTitleCase(value);
    } else if (value == 'AUTO_CANCELLED') {
      return this.getTitleCase(value);
    } else if (value == 'IN_PROGRESS') {
      return "Accept";
    } else if (value == 'NO_SHOW' || value == 'WORK_START' || value == 'COMPLETED' || value == 'DELIVERED' || value == 'REVIEW' || value == 'CLOSED') {
      return this.getTitleCase(value);
    } else if (value == 'SEND_COST') {
      return "Send Cost";
    } else if (value == 'REVICE_COST') {
      return "Cost Revision Requested";
    } else if (value == 'COST_ACCEPTED') {
      return "Cost Accepted";
    } else if (value == 'GENERATE_ESTIMATE') {
      return "Estimate Sent";
    } else if (value == 'REVICE_ESTIMATE') {
      return "Estimate Revision Requested";
    } else if (value == 'ESTIMATE_ACCEPTED') {
      return "Pending Accepted";
    } else if (value == 'REVICE_PROFORMA') {
      return "Revice Proforma";
    } else {
      const pattern = /_/gi;
      const replacement = " ";
      const result = value.replaceAll(pattern, replacement);
      return result.toProperCase();
    }

  }

  getTitleCase(value) {
    if (!value) {
      return "";
    }

    const pattern = /_/gi;
    const replacement = " ";
    const result = value.replaceAll(pattern, replacement);
    return result.toProperCase();
  }

  formatNumber(number) {
    if (number == null || number == undefined || number == "") {
      return 0;
    }
    let suffix = "";
    if (number >= 10000000) {
      number /= 10000000;
      suffix = " Cr";
    } else if (number >= 100000) {
      number /= 100000;
      suffix = " Lac";
    } else if (number >= 1000) {
      number /= 1000;
      suffix = "K";
    }

    return number.toFixed(2) + suffix;
  }

  formatToTwoDecimalPlaces(value) {
    return Number(value.toFixed(2));
  }

  getSummary() {
    WebService.getAPI({
        _action: "summary",
      })
      .then((res) => {
        Store.commit("setSummary", res);
      })
      .catch(() => {});
  }

  getFormattedCurrency(amount) {
    var fractionDigit = 0;
    if (amount % 1 > 0) {
      fractionDigit = 2;
    }

    var inr = Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: fractionDigit,
    }).format(amount);
    return inr;
  }

  checkValueEmptyOrNot(value) {
    if (value == null) {
      return value ? "" : value;
    }
  }

  DateWithTimeAgo(value) {
    console.log("stage 1 :", value);
    if (value && value != null && value != "") {
      var testDateUtc = moment.utc(value);
      console.log("stage 2 :", testDateUtc);
      var localDate = moment(testDateUtc).local();
      console.log("stage 3 :", localDate);

      var datediff = Math.floor((Date.now() - localDate) / 1000 / 86400);
      let dif = Math.abs(datediff);

      const date1 = new Date(value);
      const date2 = new Date();
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      console.log(
        "stage -4 :",
        dif,
        diffDays,
        date2.getDate(),
        "date 2 is :",
        date2
      );
      if (dif < diffDays) {
        var date = new Date(localDate),
          diff = (new Date().getTime() - date.getTime()) / 1000,
          daysDiff = Math.floor(diff / 86400);

        let daydiff = Math.abs(daysDiff);

        console.log("stage 5 :", daydiff);
        if (isNaN(daydiff) || daydiff < 0 || daydiff >= 31) return "";
        return (
          (daydiff == 0 &&
            ((diff < 60 && "Just now") ||
              (diff < 120 && "1 minute remaining") ||
              (diff < 3600 && Math.floor(diff / 60) + " minutes remaining") ||
              (diff < 7200 && "1 hour remaining") ||
              (diff < 86400 &&
                Math.floor(diff / 3600) + " hours remaining"))) ||
          (daydiff == 1 && "Yesterday") ||
          (daydiff < 7 && daydiff + " days remaining") ||
          (daydiff < 31 && Math.ceil(daydiff / 7) + " weeks remaining")
        );
      } else {
        console.log("else case");
        return diffDays + "Dasy passed";
      }
    }
    return localDate.format("DD MMM YYYY");
  }
  dateFormateForOrder(date) {
    if (date) {
      const inputDate = new Date(date);
      const currentDate = new Date();
      const isToday = inputDate.toDateString() === currentDate.toDateString();
      let hours = inputDate.getHours();
      const minutes = inputDate.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      const minutesStr = minutes < 10 ? "0" + minutes : minutes;
      const timeString = `${hours}:${minutesStr} ${ampm}`;
      if (isToday) {
        return `Today: ${timeString}`;
      } else {
        const options = {
          day: "numeric",
          month: "long",
          year: "numeric"
        };
        const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
          inputDate
        );
        return `${formattedDate}, ${timeString}`;
      }
    } else {
      ("");
    }
  }
  dateFormat(dateString) {
    if (dateString) {
      const date = new Date(dateString);
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);

      const weekdays = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const day = date.getDate();
      const month = months[date.getMonth()];
      const weekday = weekdays[date.getDay()];

      if (date.toDateString() === today.toDateString()) {
        return "Today";
      }

      if (date.toDateString() === tomorrow.toDateString()) {
        return "Tomorrow";
      }

      return `${weekday}, ${day} ${month}`;
    } else {
      return "";
    }
  }

  dateFormateForOrderWithoutDay(date) {
    if (date) {
      const inputDate = new Date(date);
      // const currentDate = new Date();
      // const isToday = inputDate.toDateString() === currentDate.toDateString();
      let hours = inputDate.getHours();
      const minutes = inputDate.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      const minutesStr = minutes < 10 ? "0" + minutes : minutes;
      const timeString = `${hours}:${minutesStr} ${ampm}`;

      const options = {
        day: "numeric",
        month: "long",
        year: "numeric"
      };
      const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
        inputDate
      );
      return `${formattedDate}, ${timeString}`;

    } else {
      ("");
    }
  }

  formatDateYYMMDD(dateString) {
    if (dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    } else {
      ("");
    }
  }
  compareDates(date1, date2) {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  }
}

export default new HelperService({});